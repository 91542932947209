import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'campaign',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign.vue')
  },
  {
    path: '/bypass',
    name: 'bypass',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/bypass.vue'),
    // props: (route) => ({ topKind: route.query.topKind, searchWord: route.query.searchWord })
    props: true
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/detail.vue'),
    props: true
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
