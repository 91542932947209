<template>
  <div id="app">
    <nav>
      <div class="fullSection">
        <div class="HeaderSection">
          <!-- 로고 영역 -->
          <div class="leftHeaderSection" @click="Home()">
            <!-- <img src="@/assets/m2.jpg" alt=""> -->
            몽땅
          </div>
          <!-- 콤보 및 검색기능 영역 -->
          <div class="middleHeaderSection">

            <div class="round">
              <!-- 콤보 선택영역 20% -->
              <div class="left">
                <select v-model="$store.state.topKind" @change="MoveDetail($store.state.topKind)">
                  <option v-for="(topList, index) in topKindListObj" :value="topList">
                    {{ topKindListObj[index] }}
                  </option>
                </select>
              </div>

              <!-- 검색 및 버튼이미지 영역 -->
              <div class="right">
                <!-- 검색영역 왼쪽 여백 -->
                <div class="left">

                </div>
                <!-- 검색 키워드 입력 영역 -->
                <div class="middle">
                  <input
                    type="text"
                    title="검색어 입력"
                    maxlength="100"
                    style="ime-mode: active;"
                    autocomplete="off"
                    placeholder="검색어를 입력해 주세요."
                    v-model="$store.state.SearchWord"
                    v-on:keyup.enter="MoveDetail($store.state.topKind)"
                  >
                </div>
                <!-- 검색 버튼 영역 -->
                <div class="right" @click="SearchWord($store.state.SearchWord)">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <router-view/>
    <div id="footer">
      <div class="footerTop">
        <div class="top">
          마케팅디자인<br>
          서울특별시 구로구 디지털로 26길 111, JNK디지털타워 708호<br>
          대표번호 : 1533-3757<br>
          문의전화 : 010-3846-0333(문자/카톡 상담가능)<br>
          사업자등록증번호: 567-87-00066<br>
          통신판매 신고번호: 제2020-서울구로-2892호
        </div>
      </div>
      <div class="footerBottom">
        <div class="top">
          COPYRIGHT© 2023 마케팅디자인. ALL RIGHTS RESERVED
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
        topKindListObj : []
    }
  },
  methods: {
    MoveCategory() {
      console.log("This? : [" + this.$store.state.topKind + "]" );
      window.scrollTo({top:location, behavior:'smooth'});
    },
    //******************************************************************************
    // 홈으로 가기
    //******************************************************************************
    Home() {
      console.log("Home?");
      this.$store.state.SearchWord = '';
      this.MoveDetail("전체");
      window.scrollTo({top:location, behavior:'smooth'});
    },

    //******************************************************************************
    // 추천이벤트로 이동
    //******************************************************************************
    MoveDetail(topKind) {
      console.log(topKind);
      this.$store.state.topKind = topKind;
      this.$router.push({ name: 'bypass', params: { topKind: topKind, searchWord: this.$store.state.SearchWord }});
    },
    //******************************************************************************
    // 상단 대분류를 누를 경우 검색기능을 없애고 전체조회함.
    //******************************************************************************
    ClearLoad() {
      this.$store.state.SearchWord = '';
      this.$router.push({ name: 'bypass', params: { reload: 'detail', }});
      this.EventList();
    },
    EventArrayList() {
      this.$store.state.SearchWord = '';
      axios.get("EventArrayList",
      {
        params: {
          topKind    : this.$store.state.topKind
        , searchWord : this.$store.state.SearchWord
        }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })
    },
    //******************************************************************************
    // 검색 후 처리
    //******************************************************************************
    SearchWord() {
      MoveDetail(this.$store.state.topKind);

      // if(this.$store.state.SearchWord == null || this.$store.state.SearchWord == '')
      //   this.$store.state.SearchWord = '';

      // axios.get("/EventArrayList",
      // {
      //   params: {
      //     searchWord: this.$store.state.SearchWord
      //   }
      // })
      // .then(response => {
      //   this.$store.state.eventListObj = response.data;
      //   this.$store.commit("ListCommit", response.data);
      // })
      // .catch(error => {
      //   console.log(error);
      // })
    },
    //******************************************************************************
    // 업종 리스트 조회
    //******************************************************************************
    MobileGetTopKindGroup() {
      axios.get('/MobileGetTopKindGroup',
      {
        params: {
          dummy    : ''
        }
      })
      .then(response => {
        this.topKindListObj = response.data;
        this.$router.push({ name : 'detail' });
      })
      .catch(error => {
        console.log(error);
      })
    },
  },
  created() {
    document.title = "[몽땅] 이벤트의 모든 것";
    window.scrollTo(0,0);

    this.MobileGetTopKindGroup();

    this.$store.state.topKind    = '전체';
    this.$store.state.SearchWord = '';

    console.log("Start : " + this.$route.params.topKind);

    // this.EventArrayList();
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

@font-face { font-family: "SCDream1"; src: url("../fonts/SCDream1.otf"); }
@font-face { font-family: "SCDream2"; src: url("../fonts/SCDream2.otf"); }
@font-face { font-family: "SCDream3"; src: url("../fonts/SCDream3.otf"); }
@font-face { font-family: "SCDream4"; src: url("../fonts/SCDream4.otf"); }
@font-face { font-family: "SCDream5"; src: url("../fonts/SCDream5.otf"); }
@font-face { font-family: "SCDream6"; src: url("../fonts/SCDream6.otf"); }
@font-face { font-family: "SCDream7"; src: url("../fonts/SCDream7.otf"); }
@font-face { font-family: "SCDream8"; src: url("../fonts/SCDream8.otf"); }
@font-face { font-family: "SCDream9"; src: url("../fonts/SCDream9.otf"); }

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
nav {
  padding: 0px;
  margin: 0px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
.fullSection {
  box-sizing: border-box;
  padding-bottom: 30px;
}





.HeaderSection {
  padding-top: 1px;
  width: 100%;
  height: 45px;
  display: flex;
  background-color: #fff;
  position: fixed;
  border-bottom: 1px solid #e6e6e6;
}
.HeaderSection .leftHeaderSection {
  width: 20%;
  font-family: 'SCDream6';
  padding-top: 12px;
  padding-left: 0px;
}
.HeaderSection .leftHeaderSection img {
  width: 45px;
  text-align: left;
  margin-top: -3px;
}
.HeaderSection .middleHeaderSection {
  width: 100%;
}
.HeaderSection .middleHeaderSection .round {
  width: 100%;
  display: flex;
}
.HeaderSection .middleHeaderSection .round .left select {
  width: 60px;
  border: 1px solid;
  position: relative;
  top: 6px;
  height: 29px;
  margin-right: 5px;
  border-radius: 50px;
  background-color: #fff;
  border: 1px solid #aaa;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}
.HeaderSection .middleHeaderSection .round .right {
  width: 78%;
  border: 1px solid #aaa;
  display: flex;
  border-radius: 50px;
  position: relative;
  top: 6px;
}
.HeaderSection .middleHeaderSection .round .right .left {
  width: 15px;
}
.HeaderSection .middleHeaderSection .round .right .middle {
  padding: 0px;
  width: 100%;
  height: 28px;
}
.HeaderSection .middleHeaderSection .round .right .middle input[type="text"] {
  padding-top: 2px;
  width: 100%;
  height: 95%;
  box-sizing: border-box;
  border: none;
  outline: 0;
}
.HeaderSection .middleHeaderSection .round .right .right {
  width: 30px;
  border: none;
}














#footer {
  border-top: 3px solid #e5e5e5;
  text-align: center;
  padding: 10px;
}
#footer > div {
  margin: 0 auto;
  padding: 13px;
  text-align: left;
}
#footer .footerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
#footer .footerTop .bottom img {
  margin-right: 15px;
  vertical-align: middle;
}
#footer .footerBottom .bottom {
  height: 13px;
}
#footer .footerBottom .bottom a {
  padding: 0 18px;
  float: right;
}
#footer .footerBottom .bottom a:last-child {
  position: relative;
}
#footer .footerBottom .bottom a:last-child::before {
  clear: both;
  content: "";
  width: 1px;
  height: 10px;
  left: -0.5px;
  top: 50%;
  background: #e0e0e0;
  position: absolute;
  transform: translateY(-50%);
}
#footer .top,
#footer .bottom,
#footer .bottom a {
  letter-spacing: -0.48px;
  color: #222;
  font-size: 12px;
}
</style>
