import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      selectList   : '00'
    , eventListObj : ''   // EventList
    , bodyListObj  : ''   // EventList
    , searchWord   : 'LG' // 검색어
    , listviewTp   : '00'
    , topKind      : ''
  },
  getters: {},
  mutations: {
    ListCommit(state, payload) {
      state.eventListObj = payload;
    }
  },
  actions: {},
  modules: {},
})
